import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import Papa from 'papaparse';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import importIcon from 'assets/images/icons/import.svg';
import searchIcon from 'assets/images/icons/search.svg';
import AddDataSource from './AddDataSource';
import AlertModal from 'components/common-components/AlertModal';
import DataSourceActions from './DataSourceActions';
import FormButton from 'components/form-components/FormButton';
import FormLoadingButton from 'components/form-components/FormLoadingButton';
import TextField from 'components/form-components/TextField';
import { addDataSourceSchema, typeOptions } from '../utils';
import { CallAPI } from 'actions/General';
import { notificationKey } from 'config/config';
import { uploadImage } from 'pages/company/utils';

const DatabaseHeader = ({ editRecord, getAllDataSources, selectedRows, clearEditRecord }) => {
  const [searchText, setSearchText] = useState('');
  const [importedFile, setImportedFile] = useState(null);
  const { t } = useTranslation();

  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const openSelectedFile = async (e) => {
    const file = e.target.files[0];
    if (file) setImportedFile(file);
  };

  return (
    <div className='section-header d-flex justify-content-between px-2'>
      <div className='d-flex align-items-center gap-3'>
        <TextField
          value={searchText}
          name='search'
          handleChange={(_, value) => setSearchText(value)}
          placeholder={t('field_search')}
          icon={searchIcon}
          classes='mb-0'
          shrink
        />

        <DataSourceActions
          ids={selectedRows}
          isActionButton={false}
          type={'data_sources'}
          actionsCallBack={getAllDataSources}
          actionData={{}}
        />
      </div>
      <div className='d-flex gap-3 align-items-center'>
        <FormButton
          text='button_import'
          onClick={handleUploadClick}
          variant='green-2'
          icon={importIcon}
        />
        <input
          className='d-none'
          ref={fileInputRef}
          type='file'
          accept='.csv'
          onChange={openSelectedFile}
        />
        <AddDataSource
          editRecord={editRecord}
          onSave={getAllDataSources}
          clearEditRecord={clearEditRecord}
        />
      </div>
      <DatabaseAddModal
        show={!!importedFile}
        file={importedFile}
        closeModal={() => setImportedFile(null)}
      />
    </div>
  );
};
DatabaseHeader.propTypes = {
  selectedRows: PropTypes.array.isRequired,
  getAllDataSources: PropTypes.func.isRequired,
  editRecord: PropTypes.object,
  clearEditRecord: PropTypes.func.isRequired,
};

const DatabaseAddModal = ({ show, file, closeModal }) => {
  const { t } = useTranslation();

  const [values, setValues] = useState({ schemaName: '', dataSourceType: '', externalId: '' });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (field, value) => {
    setTouched({ ...touched, [field]: true });
    setErrors({ ...errors, [field]: undefined });
    setValues({ ...values, [field]: value });
  };

  const validateAndSubmit = async () => {
    try {
      setLoading(true);
      await addDataSourceSchema.validate(values, { abortEarly: false });

      const validColumnTypes = typeOptions.map((o) => o.value);
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: async (result) => {
          console.log('Parsed CSV result:', result);
          let isCSVValid = false;
          let errorMessage = '';
          // console.log('Data', result.data);

          if (result.data.length > 1) {
            const headerRow = result.data[0];
            const typeRow = result.data[1];
            console.log({ headerRow, typeRow });

            if (result.data.every((row) => row.length === headerRow.length)) {
              if (typeRow.every((t) => validColumnTypes.includes(t))) {
                // console.log('All same size');

                const dataRows = cloneDeep(result.data);
                dataRows.splice(0, 2);
                console.log(dataRows);

                // Now validating all rows
                if (dataRows.length === 0) isCSVValid = true;
                else {
                  const validationResult = validateDataRows(typeRow, dataRows);
                  console.log('validationResult', validationResult);
                  if (validationResult.length === 0) isCSVValid = true;
                  else errorMessage = 'Data is not valid';
                }
              } else errorMessage = 'Column types are Invalid';
            } else errorMessage = 'Some data is missing';
          } else errorMessage = 'Header rows are missing';

          if (isCSVValid) {
            console.log('CSV is Valid');
            await submit();
          } else {
            toast(`Error: ${errorMessage}`, {
              style: { borderRadius: '8px', background: 'red', color: '#fff' },
            });
            setLoading(false);
          }
        },
        error: (err) => {
          console.log('ERROR', err);
          toast(`Error: in parsing`, {
            style: { borderRadius: '8px', background: 'red', color: '#fff' },
          });
          setLoading(false);
          // closeModal();
        },
      });
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });
      setErrors(schemaErrors);
      setLoading(false);
    }
  };

  const submit = async () => {
    try {
      await addDataSourceSchema.validate(values, { abortEarly: false });

      setLoading(true);
      const fileData = { fileName: `import_data_source_${Date.now()}.csv` };
      const response = await CallAPI('GET_PUBLIC_UPLOAD_URL', { ...fileData }, null, null);
      if (response.status) {
        await uploadImage(file, response.data, null);

        const payload = {
          ...values,
          fileName: fileData.fileName,
          filePathUrl: response.data.url,
          locale: 'en',
        };
        const result = await CallAPI('IMPORT_DATA_SOURCE', payload, null, setLoading);
        if (result.status) {
          toast(t(notificationKey['data_source_import_started']), {
            style: { borderRadius: '8px', background: '#000', color: '#fff' },
          });
          closeModal();
        }
      }
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });
      setErrors(schemaErrors);
      setLoading(false);
    }
  };

  const validateDataRows = (typeRow, dataRows) => {
    const geoCoordinatesRegex = /^\d+(\.\d+)?,\s\d+(\.\d+)?$/;
    // Date format regex (YYYY-MM-DD)
    // const dateTimeRegex = /^\d{4}-\d{2}-\d{2}$/;

    const errors = [];
    dataRows.forEach((row, rowIndex) => {
      row.forEach((value, colIndex) => {
        const type = typeRow[colIndex];
        let isValid = true;

        if (value !== '') {
          switch (type) {
            case 'text':
              if (typeof value !== 'string') isValid = false;
              break;

            case 'number':
              if (isNaN(Number(value))) isValid = false;
              break;

            case 'geoCoordinates':
              if (!geoCoordinatesRegex.test(value)) isValid = false;
              break;

            case 'dateTime':
              if (isNaN(new Date(value).getTime())) isValid = false; // Not a valid date
              break;

            default:
              isValid = false;
          }
        }

        if (!isValid) {
          errors.push(
            `Row ${rowIndex + 1}, Column ${colIndex + 1} (${type}): Invalid value '${value}'`,
          );
        }
      });
    });
    return errors;
  };

  return (
    <AlertModal show={show} actionButtons={[]}>
      <div className='w-75 d-flex gap-2 flex-column align-items-center'>
        <label className='theme-size-1_3 theme-text-black-1'>
          {t('alert_data_source_import_title')}
        </label>
        <label className='w-100'>
          <span className='theme-size-1_1 theme-text-black-1'>
            {t('alert_data_source_import_file')}{' '}
          </span>
          {file?.name ?? ''}
        </label>
        <TextField
          name='schemaName'
          displayName={t('field_data_source_name')}
          placeholder={t('field_data_source_name')}
          handleChange={handleChange}
          value={values.schemaName}
          error={errors.schemaName}
          classes='mb-1'
          shrinkFieldOnly
        />
        <TextField
          name='dataSourceType'
          displayName={t('field_data_source_type')}
          placeholder={t('field_data_source_type')}
          handleChange={handleChange}
          value={values.dataSourceType}
          error={errors.dataSourceType}
          classes='mb-1'
          shrinkFieldOnly
        />
        <TextField
          name='externalId'
          displayName={t('field_external_id')}
          placeholder={t('field_external_id')}
          handleChange={handleChange}
          value={values.externalId}
          error={errors.externalId}
          shrinkFieldOnly
        />
        <div className='d-flex w-100 gap-3 justify-content-center'>
          <FormLoadingButton
            text='button_save'
            variant='green-1'
            loading={loading}
            onClick={validateAndSubmit}
          />
          <FormButton text='button_cancel' variant='white-1' onClick={closeModal} />
        </div>
      </div>
    </AlertModal>
  );
};

DatabaseAddModal.propTypes = {
  show: PropTypes.bool.isRequired,
  file: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
};

export default DatabaseHeader;
