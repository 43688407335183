import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import toast from 'react-hot-toast';
import dotsIcon from 'assets/images/icons/table-icons.svg';
import dropdownIcon from 'assets/images/icons/dropdown-arrow-white.svg';
import AddAdmin from './AddAdmin';
import AddTags from './user-details/AddTags';
import AlertModal from 'components/common-components/AlertModal';
import ConfirmationModal from 'components/common-components/ConfirmationModal';
import FormButton from 'components/form-components/FormButton';
import { CallAPI } from 'actions/General';
import { allAccessedRoles, permissionKeys } from 'config/config';
import { isPermissionAvailable } from 'config/permissionUtils';

function UserActions({ ids, isActionButton, type, actionsCallBack, actionData }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [removeAdminConfirmModal, setRemoveAdminConfirmModal] = useState(false);
  const [promote, setPromote] = useState(false);
  const [displayedMenu, setDisplayedMenu] = useState([]);
  const [openTagsDialog, setOpenTagsDialog] = useState(false);
  const [isAccessError, setIsAccessError] = useState(false);

  const auth = useSelector((state) => state.auth);

  const checkActionAllowedToPerform = () => {
    if (
      (actionData.actionAllowed && actionData.actionAllowed === true) ||
      type.indexOf('row') === 0
    )
      return true;
    else {
      setIsAccessError(true);
      setShowDeleteConfirm(true);
      return false;
    }
  };

  const tagUser = () => {
    if (checkActionAllowedToPerform()) setOpenTagsDialog(true);
  };
  const applyUserAction = async (action) => {
    if (checkActionAllowedToPerform()) {
      let data = {
        users: ids,
        action: action,
      };
      await CallAPI('USER_ACTIONS', data, null, null, null, null);
      setShowDeleteConfirm(false);
      actionsCallBack();
    }
  };
  const confirmDeletion = () => setShowDeleteConfirm(true);

  const makeAdmin = () => {
    if (checkActionAllowedToPerform()) {
      if (actionData && actionData.userRole != 'owner') {
        if (
          auth.profile.role === 'owner' ||
          isPermissionAvailable(permissionKeys.system_profiles_read)
        )
          setPromote(true);
        else toast.error(t('alert_profile_access_error'));
      }
    }
  };
  const removeAdmin = async () => {
    await CallAPI(
      'ASSIGN_PROFILE_TO_USER',
      { userId: ids[0].id, userRole: 'employee' },
      null,
      null,
      null,
      null,
    );
    setRemoveAdminConfirmModal(false);
    actionsCallBack();
  };

  const updateRequestStatus = async (status) => {
    if (checkActionAllowedToPerform()) {
      let data = {
        users: ids,
        action: status,
      };
      await CallAPI('UPDATE_JOIN_STATUS', data, null, null, null, null);
      actionsCallBack();
    }
  };

  const sendBugReport = async () => {
    const userIds = actionData.notificationUserId ? [actionData.notificationUserId] : [];
    const response = await CallAPI('SEND_BUG_REPORT', { userIds }, null, null, null, null);
    if (response.status) toast.success(t('alert_bug_report_requested_successfully'));
    else toast.error(t('alert_bug_report_request_failed'));
  };
  useEffect(() => {
    let userActionsList = [
      {
        allowedFrom: ['user', 'admin', 'row_user', 'row_admin'],
        permission:
          isPermissionAvailable(permissionKeys.employees_users_create) && ids.length === 1,
        name: 'action_tag',
        action: tagUser,
      },
      {
        name: 'action_delete',
        permission: isPermissionAvailable(permissionKeys.employees_users_delete),
        allowedFrom: ['row_user', 'row_admin', 'row_pending', 'user', 'admin', 'pending'],
        action: () => {
          if (checkActionAllowedToPerform()) confirmDeletion();
        },
      },
      {
        allowedFrom: ['row_user', 'row_admin', 'user', 'admin'],
        permission: isPermissionAvailable(permissionKeys.employees_users_create),
        name: 'action_archive',
        action: () => applyUserAction('archive'),
      },
      {
        name: 'action_restore',
        permission: isPermissionAvailable(permissionKeys.employees_users_create),
        allowedFrom: ['row_user_archived', 'archived'],
        action: () => applyUserAction('archive_restore'),
      },
      {
        name: 'action_activity',
        permission: isPermissionAvailable(permissionKeys.employees_users_read) && ids.length === 1,
        allowedFrom: ['user', 'admin', 'row_user', 'row_admin'],
        action: () => {
          if (checkActionAllowedToPerform())
            navigate('/activity_log', {
              state: { userId: actionData.userId, userName: actionData.userName },
            });
        },
      },
      {
        permission:
          (auth.profile.role === 'owner' ||
            isPermissionAvailable(permissionKeys.employees_users_create)) &&
          ids.length === 1,
        name: 'action_make_admin',
        allowedFrom: ['user', 'row_user'],
        action: makeAdmin,
      },
      {
        permission: allAccessedRoles.indexOf(auth.profile.role) > -1 && ids.length === 1,
        name: 'action_remove_admin',
        allowedFrom: ['admin', 'row_admin'],
        action: () => setRemoveAdminConfirmModal(true),
      },
      {
        name: 'action_request_bug_report',
        permission: true,
        allowedFrom: ['row_user'],
        action: () => sendBugReport(),
      },
    ];
    if (
      (auth.profile.role === 'owner' ||
        isPermissionAvailable(permissionKeys.employees_users_create)) &&
      actionData &&
      actionData.userRole
    ) {
      userActionsList = [
        ...userActionsList,
        {
          permission: ids.length === 1,
          name: 'action_update_permission',
          allowedFrom: ['admin', 'row_admin'],
          action: makeAdmin,
        },
      ];
    }

    let isStatusActionAllowed = false;

    if (actionData && actionData.userRole && actionData.status === 'pending')
      isStatusActionAllowed = true;
    else isStatusActionAllowed = ids.every((user) => user.userStatus === 'pending');

    if (isStatusActionAllowed) {
      userActionsList = [
        ...userActionsList,
        {
          name: 'action_accept',
          permission: isPermissionAvailable(permissionKeys.employees_users_create),
          allowedFrom: ['row_pending', 'pending'],
          action: () => updateRequestStatus('approve'),
        },
        {
          name: 'action_reject',
          permission: isPermissionAvailable(permissionKeys.employees_users_create),
          allowedFrom: ['row_pending', 'pending'],
          action: () => updateRequestStatus('decline'),
        },
      ];
    }
    let isRoleAllowedToUpdateDetail = false;
    if (auth.profile.role === 'owner') isRoleAllowedToUpdateDetail = true;
    else if (auth.profile.role === 'admin' && actionData && actionData.userRole !== 'owner')
      isRoleAllowedToUpdateDetail = true;
    let isActionAllowed = true;
    // console.log('ids', ids);
    if (type.indexOf('row') === -1) {
      // console.log(actionData)
      isActionAllowed = actionData.actionAllowed;
      // console.log("isActionAllowed",isActionAllowed)
    }

    // ids.map((singleSelectedUser) => {
    //   if (singleSelectedUser.userRole && singleSelectedUser.userRole !== 'employee') {
    //     isActionAllowed = false;
    //   }
    // });

    setDisplayedMenu(
      userActionsList.filter(
        (item) =>
          item.allowedFrom.indexOf(type) > -1 &&
          item.permission &&
          ((type.indexOf('row') === 0 &&
            actionData &&
            actionData.userId !== auth.profile.userCompanyId &&
            isRoleAllowedToUpdateDetail) ||
            (type.indexOf('row') === -1 && isActionAllowed)),
      ),
    );
  }, [type, ids]);
  return (
    <div className='dropdown-actions'>
      {/* {displayedMenu.length > 0 && ( */}
      <DropdownButton
        disabled={ids.length > 0 && displayedMenu.length > 0 ? false : true}
        as={ButtonGroup}
        drop='bottom'
        variant='secondary'
        title={
          <>
            {isActionButton ? (
              <img src={dotsIcon} alt='Loading...' />
            ) : (
              <FormButton text='button_action' variant='green-1' icon={dropdownIcon} />
            )}
          </>
        }
      >
        {displayedMenu.map((button, index) => (
          <Dropdown.Item
            onClick={button.action}
            key={index}
            className='items theme-size-1 theme-text-grey-1 px-3 py-2 cursor-pointer'
          >
            {t(button.name)}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      {/* )} */}
      <AlertModal
        show={showDeleteConfirm}
        showCloseButton={true}
        closeModal={() => setShowDeleteConfirm(false)}
        variant='delete'
        message={isAccessError ? 'alert_authorize_error' : 'alert_delete_user'}
        actionButtons={[
          {
            text: t(isAccessError ? 'button_ok' : 'button_confirm'),
            variant: 'red-1',
            onClick: () =>
              isAccessError ? setShowDeleteConfirm(false) : applyUserAction('delete'),
          },
          {
            text: t('button_cancel'),
            variant: 'white-1',
            onClick: () => setShowDeleteConfirm(false),
          },
        ]}
      />
      <ConfirmationModal
        show={removeAdminConfirmModal}
        title={t('alert_common_title')}
        message={t('alert_remove_admin_message')}
        actionList={[
          {
            color: 'black-1',
            text: t('button_cancel'),
            onClick: () => setRemoveAdminConfirmModal(false),
          },
          { color: 'red-1', text: t('button_remove'), onClick: () => removeAdmin() },
        ]}
      />
      {promote && (
        <AddAdmin
          setPromoteAdmin={() => {
            setPromote(false);
            actionsCallBack();
          }}
          data={ids}
          adminProfile={actionData ? actionData.adminProfile : null}
          userRole={actionData ? actionData.userRole : undefined}
        />
      )}
      {openTagsDialog && (
        <AddTags
          close={() => {
            setOpenTagsDialog(false);
            actionsCallBack();
          }}
          userDetail={{ id: actionData.userId, title: actionData.userName }}
          userTags={actionData.tags}
        />
      )}
    </div>
  );
}
UserActions.propTypes = {
  ids: PropTypes.array.isRequired,
  actionData: PropTypes.object,
  type: PropTypes.string.isRequired,
  isActionButton: PropTypes.bool.isRequired,
  actionsCallBack: PropTypes.func.isRequired,
};
export default UserActions;
